import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Row, Select, Typography, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "components/toolbar/Editor";

const { Title } = Typography

const initialState = { abouttxt: "", dateCreated: "", status: "" }

export default function UpdateTodo() {

  const [state, setState] = useState(initialState)
  const [isProcessing, setIsProcessing] = useState(false)
  const [value,setValue] = useState('')
  const navigate = useNavigate()
  const params = useParams()

  const getDocument = useCallback(async () => {

    const docRef = doc(firestore, "homeaboutdocs", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data()
      setState(obj)
      setValue(obj.abouttxt)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("About text is not found!")
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let { abouttxt,  dateCreated,  status } = state

    if (abouttxt.length<20) { return message.error("Please enter some more text!") }

    const obj = {
      ...state,
     abouttxt:value, dateCreated, status,
      dateModified: new Date().getTime(),
    }

    setIsProcessing(true)
    try {
      await setDoc(doc(firestore, "homeaboutdocs", obj.id), obj);
      message.success("Home about text updated successfully.")
      navigate("/dashboard/")
    } catch (e) {
      // console.error("Error adding document: ", e);
      message.error('Something went wrong while updating home about text.')
    }
    setIsProcessing(false)
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Update Home About</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                
                  <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={state.status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                  <Form.Item label="About" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder={"Write about your team and research..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                      

                    </Form.Item>
                  </Col>

                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Update</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
