import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Select, Space, Tooltip, Typography, message } from 'antd';
import { firestore, storage } from '../../../config/firebase';
import { collection, deleteDoc, doc, getDocs, query, serverTimestamp, setDoc } from "firebase/firestore";
import { useAuthContext } from 'contexts/AuthContext';
import 'react-quill/dist/quill.snow.css';
import { DeleteOutlined, EditOutlined} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom';
import { deleteObject, listAll, ref } from 'firebase/storage';

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { category: ""}

export default function ResearchCategory() {
  const [state, setState] = useState(initialState)
  const [allDocuments, setAllDocuments] = useState([])
  const [documents, setDocuments] = useState([])
  const [status, SetStatus] = useState("active")
  const [isProcessing, setIsProcessing] = useState(false)
  const { user } = useAuthContext()
  const navigate = useNavigate()

  const handleChange = e => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
    // setState({...state,[e.target.name]:e.target.value})  second method
  }

  const getTodos = async () => {

    const q = query(collection(firestore, "researchcategorydocs"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    setDocuments(array)
  }
  useEffect(() => {
    getTodos()
  }, [])
  useEffect(() => {
    const filteredDocuments = allDocuments.filter(todo => todo.status === status)
    setDocuments(filteredDocuments)
  }, [allDocuments, status])


  let { category} = state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let { fullname, email, phone, address, Message } = state;
    category = category.trim();
    category = category.toLowerCase()
    // Message = Message.trim()
    if (category.length < 3) {
      return message.error("Category is very short!")
    }
    const formData = {
      category,
      status:'active',
      id: window.getRandomId(),
      dateCreated: serverTimestamp(),
      createdBy: {
        name: user.name,
        email: user.email,
        uid: user.uid,
      }
      
    }

    setIsProcessing(true)
      try {
        await setDoc(doc(firestore, "researchcategorydocs", formData.id), formData);
        // console.log("Document written with ID: ", docRef.id);
        message.success("Category is added Successfully...")
        setState(initialState)
      } catch (e) {
        // console.error("Error adding document: ", e);
        return message.error("Something went wrong, your message doesn't send.")
      }
      setIsProcessing(false)
    }
    const handleDelete = async (todo) => {
        try {
          // Delete the document from Firestore
          await deleteDoc(doc(firestore, "researchcategorydocs", todo.id));
      
          // Update the local state
          let documentsAfterDelete = documents.filter(doc => doc.id !== todo.id);
          setAllDocuments(documentsAfterDelete);
          setDocuments(documentsAfterDelete);
      
          // Create a reference to the images directory
          const imagesRef = ref(storage, 'images/');
      
          // List all files in the images directory
          const listResult = await listAll(imagesRef);
      
          // Filter files that include the todo.id in their name
          const filesToDelete = listResult.items.filter(item => item.name.includes(todo.id));
      
          // Delete each file
          for (const fileRef of filesToDelete) {
            await deleteObject(fileRef);
          }
      
          message.success("Deleted successfully!");
        } catch (err) {
          console.error(err);
          message.error("Something went wrong while deleting todo");
        }
      };
      function capitalizeWords(str) {
        if (typeof str !== 'string') return '';
        return str.split(' ').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ');
      }
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Research Categories</Title>
                  <Divider />
                  <div className="row d-flex">
                    <Col xs={24}>
                      <Form.Item label="Category">
                        <Input placeholder="Enter research category..." value={category} name='category' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                   
                  </div>

               <div className="row justify-content-center">
               <div className="col-md-6">
                    <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Add
                    </Button>
                  </Form.Item>
                </div>
               </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col text-center">
              <h1 className=' my-3'>Category Names</h1>
              <Select placeholder="Select status" onChange={status => SetStatus(status)}>
                {["active", "inactive"].map((status, i) => {
                  return <Select.Option key={i} value={status}>{status}</Select.Option>
                })}
              </Select>
            </div>
          </div>
          <Divider />
        <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Category Name</th>
                      <th>CreatedBy</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((todo, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td>{capitalizeWords(todo.category)}</td>
                          <td>{todo.createdBy.name}</td>
                          <td>{todo.dateCreated ? dayjs(todo.dateCreated.seconds*1000).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>{todo.status}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(todo) }} /></Tooltip>
                              <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/research-category/${todo.id}`) }} /></Tooltip>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}


