import React from 'react'
import About from './About'

export default function index() {
  return (
    <>
    <About />
    </>
  )
}
