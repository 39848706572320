import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Col, Form, Input, Typography, message } from 'antd';
import { firestore } from '../../../config/firebase';
import { collection, doc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { fullname: "", email: "", }

export default function Contact() {
  const [state, setState] = useState(initialState)
  const [value, setValue] = useState("")
  const [documents, setDocuments] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false)

  const handleChange = e => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
    // setState({...state,[e.target.name]:e.target.value})  second method
  }
  const getTodos = async () => {
    const q = query(collection(firestore, 'contactdocs'), where('status', '==', 'active'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });

    setDocuments(array);
  };
  useEffect(() => {
    getTodos();
  }, []);
  let { fullname, email, } = state;


  const handleSubmit = async (e) => {
    e.preventDefault();
    let { fullname, email, } = state;
    fullname = fullname.trim();

    if (fullname.length < 3) {
      return message.error("Please fill the name correctly.")
    }
    if (!window.isEmail(email)) {
      return message.error("Enter the Email correctly!")
    }
    if (value.length < 20) {
      return message.error('Your message is very short!')
    }

    const formData = {
      fullname, email, Message: value,
      id: window.getRandomId(),
      dateCreated: serverTimestamp()
    }

    setIsProcessing(true)

    try {
      await setDoc(doc(firestore, "messagedocs", formData.id), formData);
      // console.log("Document written with ID: ", docRef.id);
      message.success("Your message sent successfully.")
      setState(initialState)
    } catch (e) {
      // console.error("Error adding document: ", e);
      return message.error("Something went wrong, your message doesn't send.")
    }
    setValue(null)
    setState(initialState)

    setIsProcessing(false)
  }


  return (
    <>
      <Helmet>
    <title>Contact NIG-NanoInterfaceGroup</title>
    <meta name="description" content="Our research team uses Atomic Force Microscopy (AFM) to study nanomaterials, including fluid dynamics between electrodes and DNA behavior and topography. User can contact us by sending messages from this contact page " />
    <meta name="keywords" content="AFM, Atomic Force Microscopy, nanomaterials, fluid dynamics,research team, nano interface research team, electrodes, DNA, nanotechnology, topography, research, surface topography, deep learning, AI, Artificial Intelligence, nano interface contact page,contact" />
      <meta name="author" content="Nano Interface Group" />
    </Helmet>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Contact</Title>
                  <div className="row d-flex">
                    <Col xs={24} md={12}>
                      <Form.Item label="Full Name" className=''>
                        <Input placeholder="Your Full Name" value={fullname} name='fullname' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="Email" >
                        <Input placeholder="Your Email" value={email} name='email' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                  </div>
                  <div className="row d-flex justify-content-center">

                    <Form.Item label="Message" >
                      <ReactQuill className='custom-react-quill' placeholder='write your message here...' value={value} onChange={setValue} />
                    </Form.Item>

                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <Form.Item>
                        <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                          Send
                        </Button>
                      </Form.Item>
                    </div>
                  </div>

                </Form>

              </div>
            </div>
          </div>
        </div>
        {documents.map((obj, i) => (
          <div className="row my-5" key={i}>
            <div className="col" dangerouslySetInnerHTML={{ __html: obj.contact }}></div>
          </div>
        ))}

      </div>
    </>
  )
}


