import React from 'react'
import Contact from './Contact'

export default function index() {
  return (
    <>
    <Contact />
    </>
  )
}
