import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import {  Button, Col, Divider, Form, Image, Input, Progress, Select, Space, Tooltip, Typography, message } from 'antd';
import { firestore, storage } from '../../../config/firebase';
import { collection, deleteDoc, doc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { useAuthContext } from 'contexts/AuthContext';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "components/toolbar/Editor";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import { DeleteOutlined, EditOutlined} from '@ant-design/icons';
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom';

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { category:"" }

export default function ResearchInterest() {
  const [state, setState] = useState(initialState)
  const [status, SetStatus] = useState("active")
  const [value, setValue] = useState("")
  const [allDocuments, setAllDocuments] = useState([])
  const [documents, setDocuments] = useState([])
  const [categories, setCategories] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const { user } = useAuthContext()
  const navigate = useNavigate()

  let { category } = state;
  const getCategories = async () => {
    const q = query(collection(firestore, "researchcategorydocs"),where('status', '==', 'active'))
    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // docategoriesis never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setCategories(array)
  }
  const getTodos = async () => {

    const q = query(collection(firestore, "researchinterestdocs"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    setDocuments(array)
  }
  useEffect(() => {
    getCategories();
    getTodos();
  }, [])

  useEffect(() => {
    const filteredDocuments = allDocuments.filter(todo => todo.status === status)
    setDocuments(filteredDocuments)
  }, [allDocuments, status])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { category } = state;
    // name = name.trim();

   
    const formData = {
      description:value,category,
      id: window.getRandomId(),
      dateCreated: serverTimestamp(),
      status: "active",
      createdBy: {
        name: user.name,
        email: user.email,
        uid: user.uid,
      }
    }

    setIsProcessing(true)
    if(file){
      if(file.size>300000){
        return message.error("Image size should less than 300kb!")
      }
      uploadFile(formData)
    }
    else{
      createDocument(formData)
    }
  }
  const createDocument = async (txt) => {
    try {
      await setDoc(doc(firestore, "researchinterestdocs", txt.id), txt);
      message.success("Added successfully.")
    } catch (e) {
      console.error("Error adding document: ", e);
      message.error('something went wrong!')
    }
    setValue('')
    setState(initialState)
    setFile(null)
    setIsProcessing(false)
    
  }
  const uploadFile = (todo) => {

    const fileName = todo.id
    var fileExtension = file.name.split('.').pop();

    const storageRef = ref(storage, `images/${fileName}.${fileExtension}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.floor(progress))
      },
      (error) => {
        message.error("Something went wrong while uploading file")
        // Handle unsuccessful uploads
        
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let data = { ...todo, file: downloadURL }
          createDocument(data)
        });
      }
    );
  }
  const handleDelete = async (todo) => {
    try {
      // Delete the document from Firestore
      await deleteDoc(doc(firestore, "researchinterestdocs", todo.id));
  
      // Update the local state
      let documentsAfterDelete = documents.filter(doc => doc.id !== todo.id);
      setAllDocuments(documentsAfterDelete);
      setDocuments(documentsAfterDelete);
  
      // Create a reference to the images directory
      const imagesRef = ref(storage, 'images/');
  
      // List all files in the images directory
      const listResult = await listAll(imagesRef);
  
      // Filter files that include the todo.id in their name
      const filesToDelete = listResult.items.filter(item => item.name.includes(todo.id));
  
      // Delete each file
      for (const fileRef of filesToDelete) {
        await deleteObject(fileRef);
      }
  
      message.success("Deleted successfully!");
    } catch (err) {
      console.error(err);
      message.error("Something went wrong while deleting todo");
    }
  };
  function capitalizeWords(str) {
    if (typeof str !== 'string') return '';
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-11">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Research Interest Pages</Title>
                  <div className="row d-flex">
                  <Col xs={24} lg={12}>
                    <Form.Item label="Select Research Page or Category">
                      <Select value={category} onChange={category => setState(s => ({ ...s, category }))}>
                        {categories.map((obj, i) => {
                          return <Select.Option key={i} value={obj.category}>{capitalizeWords(obj.category)}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col> 
                    <Col xs={12} lg={8}>
                    <Form.Item label="Image (not mandatory)">
                      <Input type='file' placeholder='Upload picture' onChange={e => { setFile(e.target.files[0]) }} />
                    </Form.Item>
                    {isProcessing && file && <Progress percent={progress} showInfo={false} />}
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Item label="Preview">
                      {file && <Image src={URL.createObjectURL(file)} style={{ width: 50, height: 50 }} />}
                    </Form.Item>
                  </Col>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <Col span={24}>
                  <Form.Item label="" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder={"Write about your research..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  </div>
               <div className="row justify-content-center">
               <div className="col-md-6">
                    <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Add
                    </Button>
                  </Form.Item>
                </div>
               </div>
             </Form>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col text-center">
              <h1 className=' my-3'>Research Interest Data</h1>
              <Select placeholder="Select status" onChange={status => SetStatus(status)}>
                {["active", "inactive"].map((status, i) => {
                  return <Select.Option key={i} value={status}>{status}</Select.Option>
                })}
              </Select>
            </div>
          </div>
          <Divider />
        <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Category</th>
                      <th>CreatedBy</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((todo, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td><Image src={todo.file} className='rounded-circle' style={{ width: 50 }} /> </td>
                          <td>{capitalizeWords(todo.category)}</td>
                          <td>{todo.createdBy.name}</td>
                          <td>{todo.dateCreated ? dayjs(todo.dateCreated.seconds*1000).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>{todo.status}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(todo) }} /></Tooltip>
                              <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/research-interest/${todo.id}`) }} /></Tooltip>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}


