import React from 'react'
import People from './People'

export default function Index() {
  return (
    <>
    <People />
    </>
  )
}
