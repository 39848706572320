import React from 'react'
import { Route, Routes } from 'react-router-dom'
// Components
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Login from './login'
import NoPageFound from '../../components/NoPageFound/NoPageFound'
import ForgotPassword from './ForgotPassword'
import Register from './Register'

export default function Index() {
    return (
        <>
            <Header />
            <main>
            <Routes>
                <Route path='admin-login-2024' element={<Login />} />
                <Route path='admin-register-9125' element={<Register />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                {/* <Route path='reset-password' element={<ResetPassword />} /> */}
                <Route path="*" element={<NoPageFound />} />
            </Routes>
            </main>
            <Footer />
        </>
    )
}
