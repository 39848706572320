import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Divider, Form, Row, Select, Space, Tooltip, Typography, message } from 'antd'
import { collection, deleteDoc, doc, getDocs, query, setDoc } from "firebase/firestore";
import { firestore} from 'config/firebase';
import { useAuthContext } from 'contexts/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "components/toolbar/Editor";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title } = Typography

export default function WorkWithUs() {

  const { user } = useAuthContext()
  const [isProcessing, setIsProcessing] = useState(false)
  const [value,setValue] = useState("")
  const [allDocuments, setAllDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();

  const getTodos = async () => {
    const q = query(collection(firestore, 'workwithusdocs'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });
    setAllDocuments(array);
    setDocuments(array);
  };

  useEffect(() => {
    getTodos();
  }, []);
  useEffect(() => {
    const filteredDocuments = allDocuments.filter((todo) => todo.status === status);
    setDocuments(filteredDocuments);
  }, [allDocuments, status]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsProcessing(true)
    // let { title, location, date, description } = state

    if (value.length < 15) { return message.error("Please enter more!") }

    const cont = {
     description:value,
      status: "active",
      dateCreated: new Date().getTime(),
      id: Math.random().toString(36).slice(2),
      // file: "",
      createdBy: {
        fullName: user.name,
        email: user.email,
        uid: user.uid,
      }
    }

    createDocument(cont)
   
  }

  const createDocument = async (txt) => {
    try {
      await setDoc(doc(firestore, "workwithusdocs", txt.id), txt);
      message.success("Details added successfully.")
    } catch (e) {
      console.error("Error adding document: ", e);
      message.error('something went wrong!')
    }
    setValue('')
    setIsProcessing(false)
    
  }

  
  const handleDelete = async (todo) => {
    try {
      await deleteDoc(doc(firestore, 'workwithusdocs', todo.id));

      let documentsAfterDelete = documents.filter((doc) => doc.id !== todo.id);
      setAllDocuments(documentsAfterDelete);
      setDocuments(documentsAfterDelete);

      message.success('Deleted successfully.');
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while deleting home about data!');
    }
  };

  return (
    <>
       <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Work with Us</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                 
                  <Col span={24}>
                  <Form.Item label="Description" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder={"Write about your team or company etc..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                      

                    </Form.Item>
                  </Col>

                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Add</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col text-center">
              <h1 className='mt-4'>Work with Us Data</h1>
              <Select placeholder="Select status" className='mb-3' onChange={(status) => setStatus(status)}>
                {['active', 'inactive'].map((status, i) => (
                  <Select.Option key={i} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

        <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Work With Us</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((object, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{object.description}</td>
                        <td>{object.dateCreated ? dayjs(object.dateCreated).format('dddd, DD/MM/YYYY') : ''}</td>
                        <td>{object.status}</td>
                        <td>
                          <Space>
                            <Tooltip title="Delete" color="red">
                              <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(object)} />
                            </Tooltip>
                            <Tooltip title="Edit">
                              <Button type="primary" icon={<EditOutlined />} onClick={() => navigate(`/dashboard/work-with-us/${object.id}`)} />
                            </Tooltip>
                          </Space>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}
