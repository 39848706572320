import React, { useEffect, useState } from 'react'
import { Button, Space, Tooltip, message } from 'antd'
import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import { firestore} from 'config/firebase';
import 'react-quill/dist/quill.snow.css';
import { DeleteOutlined } from '@ant-design/icons';
import { FaReply } from "react-icons/fa"
import dayjs from 'dayjs';

export default function Message() {

  const [documents, setDocuments] = useState([]);

  const getTodos = async () => {
    const q = query(collection(firestore, 'messagedocs'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });
    setDocuments(array);
  };
  useEffect(() => {
    getTodos();
  }, []);

  const handleDelete = async (todo) => {
    try {
      await deleteDoc(doc(firestore, 'messagedocs', todo.id));

      let documentsAfterDelete = documents.filter((doc) => doc.id !== todo.id);

      setDocuments(documentsAfterDelete);

      message.success('Deleted successfully.');
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while deleting home about data!');
    }
  };

  return (
    <>
       <div className="container">
        <div className="row">
            <div className="col text-center">
              <h1 className='mt-4'>Messages</h1>
            </div>
          </div>

        <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Message</th>
                      <th>Time and Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((object, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{object.fullname}</td>
                        <td>{object.email}</td>
                        <td dangerouslySetInnerHTML={{__html:object.Message}}></td>
                        <td>{object.dateCreated ? dayjs(object.dateCreated.seconds*1000).format('dddd, DD/MM/YYYY,hh:mm') : ''}</td>
                        <td>
                          <Space>
                            <Tooltip title="Delete" color="red">
                              <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(object)} />
                            </Tooltip>
                            <Tooltip title="Reply">
                              <a href={'mailto:' + object.email} className='btn btn-outline-dark btn-sm'><FaReply /></a>
                            </Tooltip>
                          </Space>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

