import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { Button, Col, Form, Image, Input, Progress, Select, Typography, message } from 'antd';
import { firestore, storage } from '../../../config/firebase';
import { collection, deleteDoc, doc, getDoc, getDocs, query,  setDoc} from "firebase/firestore";
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "components/toolbar/Editor";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytesResumable } from 'firebase/storage';

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { category:"", status:"" }

export default function UpdateResearchInterest() {
  const [state, setState] = useState(initialState)
  // const [status, SetStatus] = useState("active")
  const [value, setValue] = useState("")
  const [documents, setDocuments] = useState([])
  const [categories, setCategories] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()
  const params = useParams()

  let { category,status } = state;
  const getCategories = async () => {
    const q = query(collection(firestore, "researchcategorydocs"))
    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // docategoriesis never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setCategories(array)
  }
  const getDocument = useCallback(async () => {

    const docRef = doc(firestore, "researchinterestdocs", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data()
      setState(obj)
      setValue(obj.description)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("data is not found!")
    }
  }, [params.id])
  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    getDocument()
  }, [getDocument])
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    let { category,status } = state;
    // name = name.trim();
    const obj = {
     ...state, description:value,category,status,
      dateModified: new Date().getTime(),
    }

    setIsProcessing(true)
    if (file) {
      if(file.size >300000)
        {
          setIsProcessing(false)
          return message.error("File size must less than 300kb ")
        }
      await handleDelete(obj);
      await uploadFile(obj)
    }
    else {
     await createDocument(obj)
    }
  }
  const createDocument = async (txt) => {
    try {
      await setDoc(doc(firestore, "researchinterestdocs", txt.id), txt);
      message.success("Updated successfully.")
      navigate('/dashboard/research-interest')
    } catch (e) {
      console.error("Error adding document: ", e);
      message.error('something went wrong!')
    }
    
  }
  const uploadFile = (todo) => {

    const fileName = todo.id
    var fileExtension = file.name.split('.').pop();

    const storageRef = ref(storage, `images/${fileName}.${fileExtension}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.floor(progress))
      },
      (error) => {
        message.error("Something went wrong while uploading file")
        // Handle unsuccessful uploads
        
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let data = { ...todo, file: downloadURL }
          createDocument(data)
        });
      }
    );
  }
  const handleDelete = async (todo) => {
    try {
      // Delete the document from Firestore
      await deleteDoc(doc(firestore, "researchinterestdocs", todo.id));
  
      // Update the local state
      let documentsAfterDelete = documents.filter(doc => doc.id !== todo.id);
      setDocuments(documentsAfterDelete);
  
      // Create a reference to the images directory
      const imagesRef = ref(storage, 'images/');
  
      // List all files in the images directory
      const listResult = await listAll(imagesRef);
  
      // Filter files that include the todo.id in their name
      const filesToDelete = listResult.items.filter(item => item.name.includes(todo.id));
  
      // Delete each file
      for (const fileRef of filesToDelete) {
        await deleteObject(fileRef);
      }
  
      // message.success("Deleted successfully!");
    } catch (err) {
      console.error(err);
      message.error("Something went wrong while deleting previous image");
    }
  };
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-11">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Research Interest Pages</Title>
                  <div className="row d-flex">
                  <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={category} onChange={category => setState(s => ({ ...s, category }))}>
                        {categories.map((obj, i) => {
                          return <Select.Option key={i} value={obj.category}>{obj.category}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col> 
                  <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                    <Col xs={12} lg={8}>
                    <Form.Item label="Image">
                      <Input type='file' placeholder='Upload picture' onChange={e => { setFile(e.target.files[0]) }} />
                    </Form.Item>
                    {isProcessing && file && <Progress percent={progress} showInfo={false} />}
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Item label="Preview">
                      {file && <Image src={URL.createObjectURL(file)} style={{ width: 50, height: 50 }} />}
                    </Form.Item>
                  </Col>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <Col span={24}>
                  <Form.Item label="" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder={"Write about your research..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  </div>
               <div className="row justify-content-center">
               <div className="col-md-6">
                    <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Update
                    </Button>
                  </Form.Item>
                </div>
               </div>
             </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



