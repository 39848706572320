import React from 'react'
import Privacy from './Privacy'

export default function index() {
  return (
    <>
    <Privacy />
    </>
  )
}
