import React from 'react'
import ResearchCategory from './ResearchCategory'

export default function index() {
  return (
    <>
    <ResearchCategory />
    </>
  )
}
