import React, { useEffect, useState } from 'react'
import {  Skeleton } from 'antd';
import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default function TermAndCondition() {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTodos = async () => {
        const q = query(collection(firestore, 'termsandconditiondocs'),where('status', '==', 'active'));;
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();
            array.push({ ...data, id: doc.id }); // Ensure each todo has an id
        });
        // console.log('array', array)
        setDocuments(array);
        setLoading(false);
    };
    useEffect(() => {
        getTodos();
    }, [])

    return (
        <>
            <main className='my-5'>
                <div className="container mt-3" style={{ display: loading ? 'block' : 'none' }}>
                    <div className="row">
                        <Skeleton active />;
                        <Skeleton active />;
                        <Skeleton active />;
                        <Skeleton active />;
                    </div>
                </div>
                <div className="container" style={{ display: loading ? 'none' : 'block' }}>
                    {documents.map((obj, i) => (
                        <div className="row justify-content-center" key={i}>
                            <div className="col-12 my-5" dangerouslySetInnerHTML={{ __html: obj.termAndCondition}}></div>
                        </div>
                    ))}
                </div>
            </main>
        </>
    )
}
