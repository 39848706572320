import './App.scss';
import "bootstrap/dist/js/bootstrap.bundle";
import { useAuthContext } from 'contexts/AuthContext';
import Routes from "pages/Routes"
import logo2 from 'assets/images/loader-logo.png'
import './config/globle'

function App() {

  const { isAppLoading } = useAuthContext()
  if (isAppLoading)
    return (
      // <div className="loader-container">
      //   <span className="loader"></span>
      // </div>
      //  <!--? Preloader Start -->
       <div id="preloader-active">
           <div className="preloader d-flex align-items-center justify-content-center">
               <div className="preloader-inner position-relative">
                   <div className="preloader-circle"></div>
                   <div className="preloader-img pere-text">
                       <img src={logo2} alt="logo" />
                   </div>
               </div>
           </div>
       </div>
      //  {/* <!-- Preloader Start --> */}
      
    )

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
