import React, { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './Home';
import People from './People';
import UpdatePeople from './People/UpdatePeople';
import UpdateHomeAbout from './Home/UpdateHomeAbout';
import NoPageFound from 'components/NoPageFound/NoPageFound';
import ResearchCategory from './ResearchCategory';
import { Avatar, Badge, Button, Divider, Dropdown, Layout, Menu, Space, message } from 'antd';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { IoHomeOutline,IoPeople,IoHomeSharp} from "react-icons/io5";
import { MdPublish,MdPrivacyTip,MdCategory } from "react-icons/md";
import { RiPhoneFindFill,RiTeamFill,RiDashboard3Fill,RiIndeterminateCircleFill  } from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { PiStudentFill } from "react-icons/pi";
import { FaQuestion } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";

import Logo from '../../assets/images/LWN.png';
import { signOut } from 'firebase/auth';
import { auth, firestore } from 'config/firebase';
import { useAuthContext } from 'contexts/AuthContext';
import UpdateResearchCategory from './ResearchCategory/UpdateResearchCategory';
import ResearchInterest from './ResearchInterest';
import UpdateResearchInterest from './ResearchInterest/UpdateResearchInterest';
import Publication from './Publication';
import UpdatePublication from './Publication/UpdatePublication';
import Contact from './Contact';
import UpdateContact from './Contact/UpdateContact';
import About from './About';
import UpdateAbout from './About/UpdateAbout';
import Career from './Career';
import UpdateCareer from './Career/UpdateCareer';
import FAQs from './FAQs/FAQs';
import UpdateFAQs from './FAQs/UpdateFAQs';
import Privacy from './Privacy/Privacy';
import UpdatePrivacy from './Privacy/UpdatePrivacy';
import TermAndCondtion from './Term&Condition';
import UpdateTermandCondition from './Term&Condition/UpdateTermAndCondition';
import WorkWithUs from './WorkWithUs';
import UpdateWorkWithUs from './WorkWithUs/UpdateWorkWithUs';
import Message from './Message';
import { collection, getDocs, query } from 'firebase/firestore';


const { Header, Sider, Content } = Layout;

export default function Index() {
    const { dispatch } = useAuthContext()
    const [collapsed, setCollapsed] = useState(false);
    const [documents, setDocuments] = useState([]);

    
  const getTodos = async () => {
    const q = query(collection(firestore, 'messagedocs'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });
    setDocuments(array);
  };
  useEffect(() => {
    getTodos();
  }, [])
    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                message.success("Signout successful")
                dispatch({ type: "SET_LOGGED_OUT" })
            })
            .catch(err => {
                message.error("Signout not successful")
            })
    }

    const menuItems = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: 'Profile',
        },
        {
            key: '2',
            icon: <IoHomeOutline />,
            label: <Link to="/" className="text-decoration-none">Home</Link>,
        },
        // {
        //     key: '3',
        //     icon: <SettingOutlined />,
        //     label: 'Settings',
        // },
        {
            key: '4',
            type: 'divider',
        },
        {
            key: '5',
            icon: <LogoutOutlined style={{ color: 'red' }} />,
            label: <span style={{ color: 'red' }}>Logout</span>,
            onClick: handleLogout,
        },
    ];


    const tasks = [
        { icon: IoHomeSharp, name: "home", path: '/dashboard/' },
        { icon: IoPeople, name: "People", path: '/dashboard/people' },
        { icon: MdCategory, name: "Research Categories", path: '/dashboard/research-category' },
        { icon: RiPhoneFindFill, name: "Research Interest", path: '/dashboard/research-interest' },
        { icon: MdPublish, name: "Publications", path: '/dashboard/publication' },
        { icon: FaCircleInfo, name: "About", path: '/dashboard/about' },
        { icon:  IoMdContact, name: "Contact", path: '/dashboard/contact' },
        { icon: PiStudentFill, name: "Career", path: '/dashboard/career' },
        { icon: RiTeamFill, name: "Work with us", path: '/dashboard/work-with-us' },
        { icon: FaQuestion, name: "FAQs", path: '/dashboard/faqs' },
        { icon: RiIndeterminateCircleFill , name: "Term & Condition", path: '/dashboard/term&condition' },
        { icon: MdPrivacyTip, name: "Privacy", path: '/dashboard/privacy' },
       
    ];

    return (
        <Layout hasSider>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div className="row d-flex justify-content-center align-items-center w-100 mt-4">
                    <div className="offset-1 col-2 text-white p-0">
                        <img src={Logo} width={30} alt="logo" />
                    </div>
                    <div className="col-8 p-0">
                        <strong
                            style={{
                                color: "#ffffff",
                                fontFamily: "sans-serif",
                                fontSize: "20px",
                                fontWeight: 'bolder'
                            }}
                        >
                            anoInterface
                        </strong>
                    </div>
                </div>
                <Divider style={{ borderColor: 'white', borderWidth: '2px' }} className='mt-2 mb-0' />
                <div className="container-fluid p-0 text-center">
                    <div className="row d-flex justify-content-center align-items-center w-100 py-2">
                        <div className="offset-1 col-2 text-white p-0">
                            <RiDashboard3Fill className='fs-3' style={{ color: '#ffffff' }} />
                        </div>
                        <div className="col-8 p-0 text-start">
                            <strong
                                style={{
                                    color: "#ffffff",
                                    fontFamily: "sans-serif",
                                    fontSize: "20px",
                                    fontWeight: 'bolder'
                                }}
                            >
                                Dashboard
                            </strong>
                        </div>
                    </div>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={tasks.map((object, index) => ({
                        key: String(index + 1),
                        icon: React.createElement(object.icon),
                        label: <Link className='text-decoration-none' to={object.path}>{object.name}</Link>,
                    }))}
                />
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
                <Header
                    style={{
                        padding: 0,
                        background: '#fff',
                    }}
                ><div className='d-flex justify-content-between'>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <div className='me-4 col-md-1 d-flex justify-content-center align-items-center'>
                            <Badge count={documents.length.toString()}>
                               <Link to='/dashboard/message'><MessageOutlined className='' style={{ fontSize: 25 }} /></Link> 
                            </Badge>


                            <Space className='ms-2'>
                                <Dropdown menu={{ items: menuItems }} overlayStyle={{ width: 140 }} trigger={['click']}>
                                    <Avatar icon={<UserOutlined />} />
                                </Dropdown>
                            </Space>

                        </div>
                    </div>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#fafafa",
                        borderRadius: '0',
                        textAlign: 'center',
                    }}
                >
                    <Routes>
                        <Route path='/'>
                            <Route index element={<Home />} />
                            <Route path='/homeabout/:id' element={<UpdateHomeAbout />} />
                            <Route path="/people" element={<People />} />
                            <Route path="/people/:id" element={<UpdatePeople />} />
                            <Route path="/research-category" element={<ResearchCategory />} />
                            <Route path="/research-category/:id" element={<UpdateResearchCategory />} />
                            <Route path="/research-interest" element={<ResearchInterest />} />
                            <Route path="/research-interest/:id" element={<UpdateResearchInterest />} />
                            <Route path="/publication" element={<Publication />} /> 
                            <Route path="/publication/:id" element={<UpdatePublication />} /> 
                            <Route path="/contact" element={<Contact />} /> 
                            <Route path="/contact/:id" element={<UpdateContact />} /> 
                            <Route path="/about" element={<About />} /> 
                            <Route path="/about/:id" element={<UpdateAbout />} /> 
                            <Route path="/career" element={<Career />} /> 
                            <Route path="/career/:id" element={<UpdateCareer />} /> 
                            <Route path="/faqs" element={<FAQs />} /> 
                            <Route path="/faqs/:id" element={<UpdateFAQs />} /> 
                            <Route path="/privacy" element={<Privacy />} /> 
                            <Route path="/privacy/:id" element={<UpdatePrivacy />} /> 
                            <Route path="/term&condition" element={<TermAndCondtion />} /> 
                            <Route path="/term&condition/:id" element={<UpdateTermandCondition />} /> 
                            <Route path="/work-with-us" element={<WorkWithUs />} /> 
                            <Route path="/work-with-us/:id" element={<UpdateWorkWithUs />} /> 
                            <Route path="/message" element={<Message />} /> 

                            <Route path="*" element={<NoPageFound />} />
                        </Route>
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
}