import React from 'react'
import Career from './Career'

export default function index() {
  return (
    <>
    
    <Career />
    </>
  )
}
