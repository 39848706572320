import React, { useEffect, useState } from 'react'
import { FaFacebookF,FaTwitter,FaLinkedinIn} from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { Image, Skeleton } from 'antd';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'config/firebase';
import {  useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const People = () => {
const [documents, setDocuments] = useState([]);
const [loading, setLoading] = useState(true);
const navigate = useNavigate()

const getTodos = async () => {
    const q = query(collection(firestore, 'peopledocs'),where('status', '==', 'active'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });
    // console.log('array', array)
    setDocuments(array);
    setLoading(false);
  };

  useEffect(() => {
    getTodos();
},[])

    return (
        <>
         <Helmet>
    <title>People NIG-NanoInterfaceGroup</title>
    <meta name="description" content="Our research team uses Atomic Force Microscopy (AFM) to study nanomaterials, including fluid dynamics between electrodes and DNA behavior and topography. Our Team description is here." />
    <meta name="keywords" content="AFM, Atomic Force Microscopy, nanomaterials, fluid dynamics,research team, nano interface research team, electrodes, DNA, nanotechnology, topography, research, surface topography, deep learning, AI, Artificial Intelligence" />
      <meta name="author" content="Nano Interface Group" />
    </Helmet>
        <div className="container mt-3" style={{ display: loading ? 'block' : 'none' }}>
            <div className="row">
            <Skeleton active />;
            <Skeleton active />;
            <Skeleton active />;
            </div>
        </div>
            <div className="container my-5"  style={{ display: loading ? 'none' : 'block' }}>
                <div className="row text-center">        
                    {documents.map((obj,i)=>(
                        <div key={i} className='col-md-4 my-3'>
                        <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                        <Image src={obj.file} className='card-img-top mt-4' style={{ width: 200,borderRadius:'50%',border:"1px solid grey" }} />
                            <div className="card-body mb-3">
                                <h5 className="card-title">{obj.name}</h5>
                                <span class="small text-uppercase text-muted">{obj.role}</span>
                                <p className="card-text">{obj.email}</p> 
                                <ul className="social mb-0 list-inline mt-3">
                                <li className="list-inline-item"><a href={obj.fblink} className="social-link" target='_blank'  rel="noreferrer noopener"><FaFacebookF className=' fs-4 mx-2' /></a></li>
                                <li className="list-inline-item"><a href={obj.instalink} className="social-link" target='_blank'  rel="noreferrer noopener"><RiInstagramFill className=' fs-4 mx-2' /></a></li>
                                <li className="list-inline-item"><a href={obj.twitterlink}className="social-link" target='_blank'  rel="noreferrer noopener"><FaTwitter className=' fs-4 mx-2' /></a></li>
                                <li className="list-inline-item"><a href={obj.linkedlink}className="social-link" target='_blank'  rel="noreferrer noopener"><FaLinkedinIn className=' fs-4 mx-2' /></a></li>
                            </ul>
                            <p className='text-end me-3 mt-2'><button onClick={() => { navigate(`/people/${obj.id}`) }} className="btn">Read More</button></p>
                            </div>
                        </div>
                    </div>
                    ))
                    }
                </div>
            </div>

        </>
    )
}

export default People