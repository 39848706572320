import React from 'react'
import Logo from '../../assets/images/logo.png'
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <>
        <footer className='py-2 bg-primary text-white'>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
                    <div className="col mb-3">
                        <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                            <img src={Logo} width={250} alt="Logo" />
                        </a>
                        <div className="d-flex justify-content-center">

                            <FaFacebookF className=' fs-4 mx-2' />
                            <RiInstagramFill className=' fs-4 mx-2' />
                            <FaTwitter className=' fs-4 mx-2' />
                            <FaYoutube className=' fs-4 mx-2' />

                        </div>
                    </div>

                    <div className="col mb-3"></div>

                    <div className="col mb-3">
                        <h5 className='text-white fw-bold fs-3'>About</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white">Home</a></li>
                            <li className="nav-item mb-2"><a href="/about" className="nav-link p-0 text-white">About N.I.G.</a></li>
                            <li className="nav-item mb-2"><a href="/contact" className="nav-link p-0 text-white">Contact</a></li>
                            

                        </ul>
                    </div>

                    <div className="col mb-3">
                        <h5 className='text-white fw-bold fs-3'>Explore</h5>
                        <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="/people" className="nav-link p-0 text-white">Team</a></li>
                            <li className="nav-item mb-2"><a href="/work-with-us" className="nav-link p-0 text-white">Work with us</a></li>
                            <li className="nav-item mb-2"><a href="/career" className="nav-link p-0 text-white">Careers</a></li>
                            
                        </ul>
                    </div>

                    <div className="col mb-3">
                        <h5 className='text-white fw-bold fs-3'>Rules</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/FAQs" className="nav-link p-0 text-white">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="/termandconditions" className="nav-link p-0 text-white">Terms & Condition</a></li>
                            <li className="nav-item mb-2"><a href="/privacy" className="nav-link p-0 text-white">Privacy</a></li>
                           
                        </ul>
                    </div>
                </div>
               
            </div>
            <p className='text-center'>{year} &copy; NanoInterfaceGroup. All Rights Reserved.</p>
        </footer>
        </>
    )
}
