import React from 'react'
import Register from './Register'

function Index() {
  return (
    <>
    <Register />
    </>
  )
}

export default Index