import React from 'react'
import ResearchInterest from './ResearchInterest'

export default function Index() {
  return (
    <>
    <ResearchInterest />
    </>
  )
}
