import React from 'react'
import ResearchInterest from './ResearchInterest'

export default function index() {
  return (
    <>
    <ResearchInterest />
    </>
  )
}
