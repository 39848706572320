import React from 'react'
import Publication from './Publication'

export default function Index() {
  return (
    <>
    <Publication />
    </>
  )
}
