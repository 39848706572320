import React, { useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom';
import { Image, Skeleton } from 'antd';
import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Helmet } from 'react-helmet';


export default function ResearchInterest() {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams()

    const getTodos = async () => {
        const q = query(collection(firestore, 'researchinterestdocs'), where('category', '==', params.name),
        where('status', '==', 'active'));;
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();
            array.push({ ...data, id: doc.id }); // Ensure each todo has an id
        });
        // console.log('array', array)
        setDocuments(array);
        setLoading(false);
    };
    useEffect(() => {
        getTodos();
    }, [])

    return (
        <>
          <Helmet>
    <title>Research Interest NIG-NanoInterfaceGroup</title>
    <meta name="description" content="Our research team uses Atomic Force Microscopy (AFM) to study nanomaterials, including fluid dynamics between electrodes and DNA behavior and topography.This page includes our research articles about nano materials surface topography and use deep learning to improve the image of AFM" />
    <meta name="keywords" content="AFM, Atomic Force Microscopy, nanomaterials, fluid dynamics,research team, nano interface research articles, electrodes, DNA, nanotechnology, topography, research, surface topography,nano materials reasearch, deep learning, AI, Artificial Intelligence" />
      <meta name="author" content="Nano Interface Group" />
    </Helmet>
            <main className='my-5'>
                <div className="container mt-3" style={{ display: loading ? 'block' : 'none' }}>
                    <div className="row">
                        <Skeleton active />;
                        <Skeleton active />;
                        <Skeleton active />;
                        <Skeleton active />;
                    </div>
                </div>
                <div className="container" style={{ display: loading ? 'none' : 'block' }}>
                    {documents.map((obj, i) => (
                        <div className="row justify-content-center" key={i}>
                            <div className="col-12 my-5" dangerouslySetInnerHTML={{ __html: obj.description }}></div>
                            <div className="col-md-8 text-center">
                                <div> <Image src={obj.file} style={{width:"50vw", border: "1px solid grey" }} /></div>

                            </div>
                        </div>
                    ))}



                </div>
            </main>
        </>
    )
}
