import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Home from "./Home"
import About from "./About"
import Contact from "./Contact"
import Publication from './Publication'
import NoPageFound from 'components/NoPageFound/NoPageFound'
import People from './People'
import ViewPeople from './People/ViewPeople'
import ResearchInterest from './ResearchInterest'
import Privacy from './Privacy'
import TermAndCondition from './TermAndCondition'
import WorkWithUs from './WorkWithUs'
import FAQs from './FAQs'
import Career from './Career'

export default function Index() {
    return (
        <>
            <Header />
            <Routes>
                <Route path='/'>

                <Route index element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='contact' element={<Contact />} />
                <Route path='publication' element={<Publication />} />
                <Route path='people' element={<People />} />
                <Route path='people/:id' element={<ViewPeople />} />
                <Route path='research-interest/:name' element={<ResearchInterest />} />
                <Route path='work-with-us' element={<WorkWithUs />} />
                <Route path='faqs' element={<FAQs />} />
                <Route path='termandconditions' element={<TermAndCondition />} />
                <Route path='career' element={<Career />} />
                <Route path='privacy' element={<Privacy />} />
                <Route path="*" element={<NoPageFound />} />
                </Route>
            </Routes>
            <Footer />
        </>
    )
}
