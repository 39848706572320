import React, { useState } from 'react';
import { Button, Form, Input, Typography, DatePicker, Col, Row, message, Divider } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from 'contexts/AuthContext';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from 'config/firebase';
import { doc,  setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const { Title } = Typography;

export default function Register() {
  const { dispatch } = useAuthContext();
  const [state, setState] = useState({
    name: '', username: '', email: '', password: '', confirmPassword: '', address: '', DoB: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }));

  const { name, username, email, password, confirmPassword, address, DoB } = state;
  const createUserProfile = async user => {
    const userData = {
      name,
      username,
      address,
      email: user.email,
      uid: user.uid,
      DoB,
      // dateCreated: serverTimestamp(),
      status: 'active',
      roles: ['client'],
    };

    try {
      await setDoc(doc(firestore, 'users', user.uid), userData);
      message.success('A new user has been created successfully.');
      dispatch({ type: 'SET_LOGGED_IN', payload: { user: userData } });
    } catch (e) {
      message.error('Something went wrong while creating the user profile.');
      console.error('Error adding document:', e);
    }
  };

  const handleRegister = async e => {
    e.preventDefault();
    if (name.length < 3) {
      return message.error('Please fill the name correctly.');
    }
    if (password !== confirmPassword) {
      return message.error("Passwords don't match.");
    }
    if(username !== "#admin#auth#register"){
      return message.error('You cannot register.')
    }

    setIsProcessing(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await createUserProfile(user);
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === 'auth/email-already-in-use') {
        message.error('User already exists with this email.');
      } else {
        message.error('Something went wrong with registration.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <main className='auth'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-8'>
            <div className='card py-5 w-100'>
              <div className='card-body'>
                <Form layout='vertical' onSubmit={handleRegister}>
                  <Title level={2} className='m-0 text-center'>Register</Title>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label='Full Name'>
                        <Input placeholder='Your Full Name' name='name' value={name} onChange={handleChange} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='User Name'>
                        <Input placeholder='Your User Name' prefix={<UserOutlined />} name='username' value={username} onChange={handleChange} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label='Email'>
                    <Input placeholder='Your Email' name='email' value={email} onChange={handleChange} />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label='Password'>
                        <Input.Password
                          placeholder='Write Password'
                          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          name='password'
                          onChange={handleChange}
                          value={password}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Confirm Password'>
                        <Input.Password
                          placeholder='Confirm password'
                          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          name='confirmPassword'
                          onChange={handleChange}
                          value={confirmPassword}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label='Address'>
                    <Input placeholder='Your Address' name='address' onChange={handleChange} value={address} />
                  </Form.Item>
                  <Form.Item label='Date of Birth'>
                    <DatePicker
                      className='w-100'
                      onChange={(date, dateString) => setState(s => ({ ...s, DoB: dateString }))}
                    />
                  </Form.Item>
                  <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleRegister}>
                    Register
                  </Button>
                </Form>
              </div>
              <div className='row'>
                <p className='text-center mb-0'>
                  Already Have an Account? <Link to='/auth/login' className='btn-link'>Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}