import React, { useCallback, useEffect, useState } from 'react'
import {  Image, Skeleton, message } from 'antd'
import { useParams } from 'react-router-dom'
import {  doc, getDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
import 'react-quill/dist/quill.snow.css';

const initialState = { name: "", email: "", role: "", status: "",fblink:"",instalink:"",twitterlink:"",linkedlink:"" }
export default function ViewPeople() {

  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(true);
  const params = useParams()


  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "peopledocs", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data()
      setState(obj)
      setLoading(false)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("About text is not found!")
      setLoading(false)
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  return (
    <>
    <main className='my-5'>
    <div className="container mt-3" style={{ display: loading ? 'block' : 'none' }}>
            <div className="row">
            <Skeleton active />;
            <Skeleton active />;
            <Skeleton active />;
            <Skeleton active />;
            </div>
        </div>
    <div className="container" style={{ display: loading ? 'none' : 'block' }}>
        <div className="row justify-content-between">
            <div className="col-md-6 d-flex flex-column justify-content-center">
               <h2>Name:{state.name}</h2>
               <h2>Email: <a href={'mailto:'+state.email} >{state.email}</a></h2>
               <h2>Role:{state.role}</h2>
            </div>
            <div className="col-md-6">
               <div className='text-end me-5'> <Image src={state.file} style={{ width: 300,border:"1px solid grey" }} /></div>
            </div>
        </div>
        <div className="row">
<div className="col my-5" dangerouslySetInnerHTML={{__html:state.about}}></div>
        </div>
    </div>
    </main>
    </>
  )
}
