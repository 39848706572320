import { Dropdown, Space} from 'antd'
import { firestore } from 'config/firebase'
import { useAuthContext } from 'contexts/AuthContext'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import { collection, getDocs, query, where } from 'firebase/firestore'

export default function Navbar() {
  const [categories, setCategories] = useState([])
  // const [selectedKey, setSelectedKey] = useState(null);
  const location = useLocation()
  const { isAuth } = useAuthContext()
  const params = useParams()


  const getCategories = async () => {
    const q = query(collection(firestore, "researchcategorydocs"),where('status', '==', 'active'))
    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // docategoriesis never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setCategories(array)
  }
  useEffect(() => {
    getCategories();
  }, [])
  const items = categories.map((obj,i)=>({
    label: <a href={'/research-interest/'+ obj.category} className={params.name === obj.category?'selected-tab':''}>{capitalizeWords(obj.category)}</a>,
    key : i.toString(),
    // onClick: () => setSelectedKey(i.toString())
   }))

  function capitalizeWords(str) {
    if (typeof str !== 'string') return '';
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-primary navbar-dark">
        <div className="container">
          <Link to="/" className="navbar-brand"><img className="img-fluid" src={Logo} width={300} alt="Logo" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}>Home </Link>
              </li>
              <li className="nav-item">
                <Link to="/people" className={location.pathname === '/people' ? 'nav-link active' : 'nav-link'}>People</Link>
              </li>
              <Dropdown
              

                menu={{
                  items,
                  selectable: true,
                }}
                trigger={['click']}
              className='nav-item'>
                 <div className='nav-item'>

                  <Space   className={(location.pathname.startsWith('/research-interest')) ? 'nav-link active' : 'nav-link'} style={{cursor:'pointer'}}>
                    Research Interest
                  </Space>
                 </div>
                {/* </a> */}
              </Dropdown>
              <li className="nav-item">
                <Link to="/publication" className={location.pathname === '/publication' ? 'nav-link active' : 'nav-link'}>Publication</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'}>Contact</Link>
              </li>
               {isAuth &&  <li className="nav-item"><Link to="/dashboard" className="nav-link">Dashboard</Link></li>}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
