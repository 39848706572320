import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {  Button, Col, Form, Select,Typography, message } from 'antd';
import { firestore } from '../../../config/firebase';
import {  doc, getDoc,  setDoc} from "firebase/firestore";
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "components/toolbar/Editor";

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { status:"" }

export default function UpdateCareer() {
  const [state, setState] = useState(initialState)
  // const [status, SetStatus] = useState("active")
  const [value, setValue] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  let { status } = state;
  
  const getDocument = useCallback(async () => {

    const docRef = doc(firestore, "careerdocs", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data()
      setState(obj)
      setValue(obj.career)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("data is not found!")
    }
  }, [params.id])
  useEffect(() => {
    getDocument()
  }, [getDocument])
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    let { status } = state;
    // name = name.trim();
    const obj = {
     ...state, career:value,status,
      dateModified: new Date().getTime(),
    }
    setIsProcessing(true)
 
     createDocument(obj)
    
  }
  const createDocument = async (txt) => {
    try {
      await setDoc(doc(firestore, "careerdocs", txt.id), txt);
      message.success("Updated successfully.")
      navigate('/dashboard/career')
    } catch (e) {
      console.error("Error adding document: ", e);
      message.error('something went wrong!')
    }
    setIsProcessing(false)
  }

  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-11">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Update Career</Title>
                  <div className="row d-flex">
                
                  <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <Col span={24}>
                  <Form.Item label="About" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder={"Write about your research..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  </div>
               <div className="row justify-content-center">
               <div className="col-md-6">
                    <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Update
                    </Button>
                  </Form.Item>
                </div>
               </div>
             </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



