import React, { useEffect, useState,useCallback, } from 'react';
import { Button, Col, Divider, Form, Input, Select, Typography, message } from 'antd';
import { firestore } from '../../../config/firebase';
import { doc, getDoc, setDoc } from "firebase/firestore";
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Title } = Typography;
const initialState = { category: "",status:""}

export default function UpdateResearchCategory() {
  const [state, setState] = useState(initialState)
//   const [status, SetStatus] = useState("active")
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  const handleChange = e => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
    // setState({...state,[e.target.name]:e.target.value})  second method
  }

  const getDocument = useCallback(async () => {

    const docRef = doc(firestore, "researchcategorydocs", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data()
      setState(obj)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("About text is not found!")
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  let { category,status} = state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let { fullname, email, phone, address, Message } = state;
    category = category.trim();
    category = category.toLowerCase();
    // Message = Message.trim()

    if (category.length < 3) {
      return message.error("Category is very short!")
    }
    const obj = {
        ...state,
        category, status,
        dateModified: new Date().getTime(),
      }
      

    setIsProcessing(true)
      try {
        await setDoc(doc(firestore, "researchcategorydocs", obj.id), obj);
        // console.log("Document written with ID: ", docRef.id);
        message.success("Category is added Successfully...")
        setState(initialState)
        navigate("/dashboard/research-category")
      } catch (e) {
        // console.error("Error adding document: ", e);
        return message.error("Something went wrong, your message doesn't send.")
      }
      setIsProcessing(false)
    }
    function capitalizeWords(str) {
      if (typeof str !== 'string') return '';
      return str.split(' ').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    }
    
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Update Research Category</Title>
                  <Divider />
                  <div className="row d-flex">
                    <Col xs={24} lg={12}>
                      <Form.Item label="Category">
                        <Input placeholder="Enter research category..." value={capitalizeWords(category)} name='category' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={state.status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                   
                  </div>

               <div className="row justify-content-center">
               <div className="col-md-6">
                    <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Update
                    </Button>
                  </Form.Item>
                </div>
               </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


