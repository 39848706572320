import React from 'react'
import ForgotPassword from './ForgotPassword'

function Index() {
  return (
    <>
    <ForgotPassword />
    </>
  )
}

export default Index