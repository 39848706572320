import React from 'react'
import Login from './Login'

const  Index = () => {
  return (
    <>
    <Login />
    </>
    
    )
         
}

export default Index