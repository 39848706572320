import React, { useEffect, useState } from 'react';
import { Divider, Skeleton } from 'antd';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'config/firebase';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from '../../../assets/images/1.webp';
import slide2 from '../../../assets/images/2.webp';
import slide4 from '../../../assets/images/4.webp';
import slide5 from '../../../assets/images/5.webp';
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

const sliderSettings = {
  // dots: true,
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Hero() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTodos = async () => {
    const q = query(collection(firestore, 'homeaboutdocs'),where('status', '==', 'active'));
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      array.push({ ...data, id: doc.id }); // Ensure each todo has an id
    });
    // console.log('array', array)
    setDocuments(array);
    setLoading(false)
  };

  useEffect(() => {
    getTodos();
  }, []);

  return (
    <>
    <Helmet>
    <title>Home NIG-NanoInterfaceGroup</title>
    <meta name="description" content="Our research team uses Atomic Force Microscopy (AFM) to study nanomaterials, including fluid dynamics between electrodes and DNA behavior and topography." />
    <meta name="keywords" content="AFM, Atomic Force Microscopy, nanomaterials, fluid dynamics, electrodes, DNA, nanotechnology, topography, research, surface topography, deep learning, AI, Artificial Intelligence" />
      <meta name="author" content="Nano Interface Group" />
    </Helmet>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center my-4 my-md-2">
              <h1 className="text-primary">Nano Interface Group</h1>
              <h2 style={{ color: '#FFA62F' }} className='text-center'>Pioneering Precision at the Nanoscale</h2>
              <p className="ps-sm-5 ps-3 pe-4">
                The <span><strong className="text-primary">Nano Interface Group</strong></span> is a cutting-edge research lab dedicated to studying the intricate surfaces of nanoscale materials using advanced atomic force microscopy (AFM). This multidisciplinary team of scientists and engineers is committed to exploring the topography of a variety of nano-level structures, including biological molecules like DNA and innovative nanomaterials.
              </p>
            </div>
            <div className="col-md-6 px-0">
              <div className="owl-carousel-container" style={{ maxWidth: '100% !important',overflow:'hidden' }}>
                <Slider {...sliderSettings}>
                  <div className="item"><img className="img-fluid" style={{minHeight:'440px important'}} src={slide1} alt="1st" /></div>
                  <div className="item"><img className="img-fluid" style={{minHeight:'440px important'}} src={slide2} alt="2nd" /></div>
                  <div className="item"><img className="img-fluid" style={{minHeight:'440px important'}} src={slide4} alt="3rd" /></div>
                  <div className="item"><img className="img-fluid" style={{minHeight:'440px important'}} src={slide5} alt="4th" /></div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Divider className="m-0" />
      <section className='my-5'>
      <div className="container" style={{ display: loading ? 'block' : 'none' }}>
            <div className="row">
            <Skeleton active />;
            <Skeleton active />;
            <Skeleton active />;
            <Skeleton active />;
            </div>
        </div>
        <div className="container" style={{ display: loading ? 'none' : 'block' }}>
       
                    {documents.map((object, i) => (
                      <div className='row' key={i}>
                       
                        <div className='col' dangerouslySetInnerHTML={{__html: object.abouttxt}}></div>
                     
                       
                      </div>
                    ))}
        </div>
      </section>
    </>
  );
}
