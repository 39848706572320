import React from 'react'
import TermAndCondition from './TermAndCondition'

export default function index() {
  return (
    <>
    <TermAndCondition />
    </>
  )
}
