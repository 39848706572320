import React from 'react'
import FAQs from './FAQs'

export default function index() {
  return (
    <>
    <FAQs />
    </>
  )
}
